const Button = ({
  children,
  buttonType,
  buttonSize,
  buttonContent,
  ...otherProps
}) => {
  const buttonClasses = `btn ${buttonType || ''} ${buttonSize || ''} ${
    buttonContent || ''
  }`;

  return (
    <button className={buttonClasses.trim()} {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
