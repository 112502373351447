import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBL_8Su5K3LWv6NAvhlMyvJmQDSyTQ45y8',
  authDomain: 'crade-281c9.firebaseapp.com',
  projectId: 'crade-281c9',
  storageBucket: 'crade-281c9.appspot.com',
  messagingSenderId: '613114550047',
  appId: '1:613114550047:web:35c9fb3dd2cc7acd2f822a',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app)

export { db, storage, functions }
