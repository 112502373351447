import { Fragment, useState, useEffect, lazy } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { useLanguage } from './contexts/language.context'
import CookieConsentPopup from './layout/cookies/layout/cookies.popup.layout'
import Modal from './layout/modals/modal.layout'
import Preloader from './layout/preloader/layout/preloader.layout'
import useMailchimp from './hooks/mailchimp.hook'
import { useModalContext } from './contexts/modal.context'
import ScrollToTop from './utils/scroll.to.top'
import { routePaths } from './routes/route.paths'
import './styles/main.scss'

const Navigation = lazy(() =>
  import('./layout/navigation/layout/navigation.component'),
)
const HomeRoute = lazy(() => import('./routes/home/layout/home.route'))
const AboutRoute = lazy(() => import('./routes/about/layout/about.route'))
const StoriesRoute = lazy(() => import('./routes/stories/layout/stories.route'))
const ServicesRoute = lazy(() =>
  import('./routes/services/layout/services.route'),
)
const BlogRoute = lazy(() => import('./routes/blog/layout/blog.route'))
const ContactRoute = lazy(() => import('./routes/contact/layout/contact.route'))
const CarpentryRoute = lazy(() =>
  import('./routes/services/layout/carpentry.route'),
)
const FurnitureRoute = lazy(() =>
  import('./routes/services/layout/furniture.route'),
)
const InteriorsRoute = lazy(() =>
  import('./routes/services/layout/interiors.route'),
)
const VisualsRoute = lazy(() =>
  import('./routes/services/layout/visuals.route'),
)
const PostRoute = lazy(() => import('./routes/blog/layout/post.route'))
const StoryRoute = lazy(() => import('./routes/stories/layout/story.route'))
const TermsOfUseRoute = lazy(() =>
  import('./routes/terms/layout/terms.of.use.route'),
)
const PrivacyPolicyRoute = lazy(() =>
  import('./routes/privacy/layout/privacy.policy.route'),
)
const SuccessRoute = lazy(() => import('./routes/success/layout/success.route'))

const App = () => {
  const { language, setLanguage } = useLanguage()
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  const { isOpened, closeModal, content } = useModalContext()

  const { isSending } = useMailchimp()

  const pathSegments = location.pathname.split('/').filter(Boolean)
  const currentPath = '/' + pathSegments.slice(1).join('/')

  useEffect(() => {
    const langSegment = location.pathname.split('/')[1]
    if (langSegment && langSegment !== language) {
      setLanguage(langSegment)
    }
  }, [location.pathname, language, setLanguage])

  const handlePreloaderFadeOut = () => {
    setLoading(false)
  }

  return (
    <Fragment>
      {loading && <Preloader onFadeOut={handlePreloaderFadeOut} />}

      {!loading && (
        <main>
          <Modal
            isOpened={isOpened}
            handleToggleModal={closeModal}
            content={content}
          />

          <CookieConsentPopup language={language} />

          <div className={'scroll__container'}>
            <ScrollToTop />

            <Routes>
              <Route
                path='/'
                element={<Navigate to={`/${language}`} replace />}
              />

              <Route
                path='/:lang'
                element={
                  <Navigation
                    currentLanguage={language}
                    content={content}
                    currentPath={currentPath}
                    routePaths={routePaths}
                  />
                }
              >
                <Route
                  index
                  element={
                    <HomeRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.about[language]}`}
                  element={
                    <AboutRoute language={language} isSending={isSending} />
                  }
                />

                <Route path={`${routePaths.stories[language]}/:category?`}>
                  <Route
                    index
                    element={
                      <StoriesRoute
                        language={language}
                        showcaseStoriesTitle={`${routePaths.stories[language]}`}
                      />
                    }
                  />

                  <Route
                    path=':slug'
                    element={
                      <StoryRoute language={language} isSending={isSending} />
                    }
                  />
                </Route>

                <Route path={`${routePaths.blog[language]}/:category?`}>
                  <Route
                    index
                    element={
                      <BlogRoute
                        language={language}
                        showcaseBlogTitle={`${routePaths.blog[language]}`}
                      />
                    }
                  />

                  <Route
                    path=':slug'
                    element={
                      <PostRoute language={language} isSending={isSending} />
                    }
                  />
                </Route>

                <Route
                  path={`${routePaths.services[language]}`}
                  element={
                    <ServicesRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.carpentry[language]}`}
                  element={
                    <CarpentryRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.furniture[language]}`}
                  element={
                    <FurnitureRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.interiors[language]}`}
                  element={
                    <InteriorsRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.visuals[language]}`}
                  element={
                    <VisualsRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.contact[language]}`}
                  element={
                    <ContactRoute language={language} isSending={isSending} />
                  }
                />

                <Route
                  path={`${routePaths.terms[language]}`}
                  element={<TermsOfUseRoute language={language} />}
                />

                <Route
                  path={`${routePaths.privacy[language]}`}
                  element={<PrivacyPolicyRoute language={language} />}
                />

                <Route
                  path={`${routePaths.success[language]}`}
                  element={<SuccessRoute language={language} />}
                />

                <Route
                  path='*'
                  element={
                    <HomeRoute language={language} isSending={isSending} />
                  }
                />
              </Route>
            </Routes>
          </div>
        </main>
      )}

      <div className={'line__container'}>
        <div className={'line__container__separator'}></div>
        <div className={'line__container__separator'}></div>
        <div className={'line__container__separator'}></div>
        <div className={'line__container__separator'}></div>
        <div className={'line__container__separator'}></div>
      </div>
    </Fragment>
  )
}

export default App
