const Modal = ({ isOpened, handleToggleModal, content }) => {
  return (
    <div className={isOpened ? 'modal modal__is__opened' : 'modal'}>
      <button onClick={handleToggleModal} className={'modal__controls'}>
        <div className={'modal__controls__cross__one'} />
        <div className={'modal__controls__cross__two'} />
      </button>

      <div className={'modal__inner'}>{content}</div>
    </div>
  );
};

export default Modal;
