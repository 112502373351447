import { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase/firebase.config';

const useFetchData = (
  path,
  type,
  filterField = null,
  filterValue = null,
  delay = 0
) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (delay > 0) {
          await new Promise(resolve => setTimeout(resolve, delay));
        }

        setIsLoading(true);

        if (type === 'collection') {
          let dataRef = collection(db, path);

          if (filterField && filterValue) {
            dataRef = query(dataRef, where(filterField, '==', filterValue));
          }

          const snapshot = await getDocs(dataRef);
          const fetchedData = [];
          for (const docSnap of snapshot.docs) {
            fetchedData.push({ id: docSnap.id, ...docSnap.data() });
          }

          setData(fetchedData);
        } else if (type === 'document') {
          const docRef = doc(db, path);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setData({ id: docSnap.id, ...docSnap.data() });
          } else {
            throw new Error('Document does not exist');
          }
        } else {
          throw new Error('Invalid fetch type');
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [path, type, filterField, filterValue, delay]);

  return { data, isLoading, error };
};

export default useFetchData;
