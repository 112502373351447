/* global gtag */
import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import useLocalStorage from '../../../hooks/storage.hook';
import useFetchData from '../../../hooks/fetch.hook';
import Button from '../../../components/button/layout/button.component';
import Spinner from '../../../components/spinner/layout/spinner.component';

const CookieConsentPopup = ({ language }) => {
  const initialConsentState = {
    ad_storage: false,
    analytics_storage: false,
    ad_user_data: false,
    ad_personalization: false,
    functionality_storage: false,
    personalization_storage: false,
    security_storage: false,
  };

  const [consent, setConsent] = useLocalStorage('cookieConsent', initialConsentState);
  const [isVisible, setIsVisible] = useState(!Object.values(consent).every((c) => c));
  const [isExiting, setIsExiting] = useState(false);

  const documentPath = `layout/cookies/languages/${language}`;
  const { data: documentData, isLoading: documentLoading, error: documentError } = useFetchData(documentPath, 'document');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 900000);
    return () => clearTimeout(timer);
  }, [consent]);

  const handleConsentChange = (consentType) => {
    const updatedConsent = { ...consent, [consentType]: !consent[consentType] };
    setConsent(updatedConsent);
    updateGtagConsent(updatedConsent);
  };

  const updateGtagConsent = (newConsent) => {
    if (typeof window !== 'undefined' && window.dataLayer && typeof gtag === 'function') {
      gtag('consent', 'update', {
        ad_storage: newConsent.ad_storage ? 'granted' : 'denied',
        analytics_storage: newConsent.analytics_storage ? 'granted' : 'denied',
        ad_user_data: newConsent.ad_user_data ? 'granted' : 'denied',
        ad_personalization: newConsent.ad_personalization ? 'granted' : 'denied',
        functionality_storage: newConsent.functionality_storage ? 'granted' : 'denied',
        personalization_storage: newConsent.personalization_storage ? 'granted' : 'denied',
        security_storage: newConsent.security_storage ? 'granted' : 'denied',
      });
    }
  };

  const handleAcceptAll = () => {
    const allConsentedState = {
      ad_storage: true,
      analytics_storage: true,
      ad_user_data: true,
      ad_personalization: true,
      functionality_storage: true,
      personalization_storage: true,
      security_storage: true,
    };
    setConsent(allConsentedState);
    updateGtagConsent(allConsentedState);
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      setIsVisible(false);
    }, 500);
  };

  const handleRejectAll = () => {
    const rejectedConsents = {
      ad_storage: false,
      analytics_storage: false,
      ad_user_data: false,
      ad_personalization: false,
      functionality_storage: false,
      personalization_storage: false,
      security_storage: false,
    };
    setConsent(rejectedConsents);
    updateGtagConsent(rejectedConsents);
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      setIsVisible(false);
    }, 500);
  };

  const handleAccept = () => {
    updateGtagConsent(consent);
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      setIsVisible(false);
    }, 500);
  };

  if (!isVisible) return null;

  return (
    <div className={`cookie__consent__popup ${isExiting ? 'slide__out' : 'slide__in'}`}>
      {documentError && <p>Error fetching data: {documentError.message}</p>}
      {documentLoading && <Spinner />}
      {documentData && (
        <Fragment>
          <p className='cookie__consent__popup__text'>
            {documentData.text}{' '}
            <Link className='cookie__consent__popup__text__link' to={`/${language}/${documentData.linkPath}`}>
              {documentData.link}
            </Link>.
          </p>
          <div className='cookie__consent__popup__controls'>
            {documentData && Object.keys(consent).map((consentType, index) => (
              <label key={consentType} className='contact__form__checkbox' style={{ fontSize: '0.75rem' }}>
                <input
                  type='checkbox'
                  name='consentCheckbox'
                  checked={consent[consentType]}
                  onChange={() => handleConsentChange(consentType)}
                  disabled={consentType === 'necessary'} // Assuming 'necessary' is a mandatory consent
                />
                {documentData.consentTitles[index]}
              </label>
            ))}
          </div>
          <div className={'cookie__consent__popup__control__container'}>
            <Button buttonType={'btn__primary'} buttonSize={'btn__medium'} onClick={handleAcceptAll} style={{ flex: 1, backgroundColor: "#dddddd", color: "#191919" }}>
              {documentData.acceptAll}
            </Button>
            <Button buttonType={'btn__primary'} buttonSize={'btn__medium'} onClick={handleAccept} style={{ flex: 1 }}>
              {documentData.accept}
            </Button>
            <Button buttonType={'btn__ghost'} buttonSize={'btn__medium'} onClick={handleRejectAll} style={{ flex: 1 }}>
              {documentData.reject}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CookieConsentPopup;
