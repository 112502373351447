import { useEffect, useState } from 'react';
import ImageLoader from '../../../components/loaders/image.loader.component';

const Preloader = ({ onFadeOut }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(onFadeOut, 1000);
    }, 2600);

    return () => clearTimeout(timer);
  }, [onFadeOut]);

  return (
    <div className={`preloader ${fadeOut ? 'preloader__fade__out' : ''}`}>
      <span className={'preloader__text'}>
        Crafting distinctive spaces, transformed into your lifestyle
      </span>

      <ImageLoader
        src={
          'https://firebasestorage.googleapis.com/v0/b/crade-281c9.appspot.com/o/layout%2Flogo%2Fproject__logo__white.svg?alt=media&token=34e4a248-ec73-4e4d-9a72-26366b3c1c66'
        }
        alt={'project__logo'}
        className={'preloader__image'}
        loading='lazy'
      />

      <div className={'preloader__line__container'}>
        <div className={'preloader__line__container__separator'}></div>
        <div className={'preloader__line__container__separator'}></div>
        <div className={'preloader__line__container__separator'}></div>
        <div className={'preloader__line__container__separator'}></div>
        <div className={'preloader__line__container__separator'}></div>
      </div>
    </div>
  );
};

export default Preloader;
