import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [content, setContent] = useState(null);

  const openModal = modalContent => {
    setContent(modalContent);
    setIsOpened(true);
  };

  const closeModal = () => {
    setIsOpened(false);
    setContent(null);
  };

  const setAutoClose = (delay = 5000) => {
    setTimeout(() => {
      closeModal();
    }, delay);
  };

  const handleFormSubmit = (success, message) => {
    openModal(<div>{message}</div>);
    if (success) {
      setAutoClose(5000);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        isOpened,
        openModal,
        closeModal,
        setAutoClose,
        content,
        handleFormSubmit,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
